import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ICompetition, ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {Bindings} from "data/constants/bindings";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ModalType, ProfileTab} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isNumber} from "lodash";

export interface IModalSquadProfileController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get squad(): ISquad | undefined;
	get competition(): ICompetition | undefined;
	get nextFixtures(): string[];
	get activeTab(): ProfileTab;
	get isPreseason(): boolean;
	get hideButtons(): boolean;

	handleChangeTab: (event: React.SyntheticEvent, newValue: ProfileTab) => void;
	close: () => void;
}

@injectable()
export class ModalSquadProfileController implements IModalSquadProfileController {
	@observable private _activeTab = ProfileTab.Overview;
	@observable private _fetchStatsDisposer?: ReturnType<typeof reaction>;
	@observable private _openMatchDisposer?: ReturnType<typeof reaction>;
	@observable private _isNarrowScreen: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SQUAD_PROFILE;
	}

	get squad(): ISquad | undefined {
		if (!this.modalData?.squadId) {
			return;
		}
		return this._squadsStore.getSquadById(this.modalData.squadId as number);
	}

	get competition(): ICompetition | undefined {
		if (!this.squad) {
			return;
		}
		return this._competitionsStore.getCompetitionById(this.squad.competitionId);
	}

	get nextFixtures(): string[] {
		if (!this.squad) {
			return [];
		}

		const squadId = this.squad.id;
		const games = this._roundsStore.getNextFixtures(squadId, 3);
		return games.map((game) => {
			const location = game.homeId === squadId ? "(H)" : "(A)";
			const opponentId = game.homeId === squadId ? game.awayId : game.homeId;
			const opponent = this._squadsStore.getSquadById(opponentId);

			if (!opponent) {
				return "-";
			}

			const name = this._isNarrowScreen ? opponent.abbreviation : opponent.name;
			return `${name} ${location}`;
		});
	}

	get activeTab(): ProfileTab {
		return this._activeTab;
	}

	get isPreseason(): boolean {
		return !this._roundsStore.isSeasonStarted;
	}

	protected get modalData(): IModalPayload | null {
		return this._modalsStore.modalContent;
	}

	get hideButtons(): boolean {
		return (this.modalData?.hideButtons as boolean) ?? false;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	public handleChangeTab = (event: React.SyntheticEvent, newValue: ProfileTab) => {
		this._activeTab = newValue;
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			code: error.response?.status,
			message: extractErrorMessage(error),
		});
	};

	init(): void {
		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);

		this._openMatchDisposer = reaction(
			() => this.modalData,
			() => {
				if (!this.modalData) {
					return;
				}
				const matchId = this.modalData.matchId;
				if (isNumber(matchId)) {
					this._activeTab = ProfileTab.Results;
				} else {
					this._activeTab = ProfileTab.Overview;
				}
			}
		);

		this._fetchStatsDisposer = reaction(
			() => this.modalData,
			() => {
				if (!this.modalData) {
					return;
				}
				const squadId = this.modalData.squadId;
				if (isNumber(squadId)) {
					void this._squadsStore.fetchSquadStats(squadId).catch(this.onError);
				}
			}
		);
	}

	dispose() {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);
		this._fetchStatsDisposer?.();
		this._openMatchDisposer?.();
	}
}
