import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILineupPlayers, ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
import {ModalType, RequestState} from "data/enums";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {isEqual} from "lodash";

interface IParam {
	userId: number;
}

export interface IOtherUserTeamController extends ViewController<IParam> {
	get isLoading(): boolean;
	get lineupPlayers(): ILineupPlayers;
	get lineupSquads(): number[];
}

@injectable()
export class OtherUserTeamController implements IOtherUserTeamController {
	@observable private _userId: number | null = null;
	@observable private _requestState = RequestState.PENDING;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get lineupPlayers(): ILineupPlayers {
		return this._teamStore.otherUserTeam.lineup.players;
	}

	get lineupSquads() {
		return this._teamStore.otherUserTeam.lineup.squads;
	}

	get roundId() {
		if (this._rankingsStore.ladderType === "overall") {
			return this._roundsStore.scoreRound?.id ?? 0;
		}
		return this._rankingsStore.ladderType;
	}

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			code: error.response?.status,
			message: extractErrorMessage(error),
		});
	};

	@action init(param: IParam): void {
		this._userId = param.userId;

		this._requestState = RequestState.PENDING;
		if (this.roundId) {
			this._teamStore
				.fetchOtherUserTeam(this._userId, this.roundId)
				.then(this.onSuccess)
				.catch(this.onError);
		}
	}
}
