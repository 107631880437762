import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IModalErrorController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get title(): string;

	close: () => void;
}

@injectable()
export class ModalErrorController implements IModalErrorController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.ERROR;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get title() {
		if (this.modalContent?.title) {
			return this.i18n.t(this.modalContent.title);
		}
		if (this.modalContent?.code === 401) {
			return this.i18n.t("modal.auth_error.title", "You are logged out");
		}
		return this.i18n.t("modal.error.title", "Error");
	}

	close = () => {
		// If it's an auth error we reload the page to log the user out
		if (this.modalContent?.code === 401) {
			window.location.reload();
			return;
		}
		this._modalsStore.hideModal();
	};
}
