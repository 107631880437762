import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {ModalType} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {ITeam, ITeamStore} from "data/stores/team/team.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";

export interface IDashboardController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isLoaded(): boolean;
	get isSeasonStarted(): boolean;
	get team(): ITeam;
	get hasTeam(): boolean;
	get leagues(): ILeague[];
	get round(): IRound | undefined;

	getValueDisplay: (value: number | null) => number | "-";
	getRankDisplay: (rank: number | null) => string | "-";
}

@injectable()
export class DashboardController implements IDashboardController {
	@observable private _isLoaded: boolean = false;

	get isLoaded() {
		return this._isLoaded;
	}

	get isSeasonStarted() {
		return this._roundsStore.isSeasonStarted;
	}

	get team() {
		return this._teamStore.team;
	}

	get hasTeam() {
		return this._teamStore.isTeamCreated;
	}

	get leagues() {
		return this._leaguesStore.myLeagues.leagues.slice(0, 3);
	}

	get round() {
		return this._roundsStore.currentRound;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	public getValueDisplay = (value: number | null) => {
		return value ?? "-";
	};

	public getRankDisplay = (rank: number | null) => {
		return rank ? rank.toLocaleString(this.i18n.locale) : "-";
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			code: error.response?.status,
			message: extractErrorMessage(error),
		});
	};

	async init(param: void) {
		await Promise.all([
			this._roundsStore.fetchRounds(),
			this._competitionsStore.fetchCompetitions(),
			this._squadsStore.fetchSquads(),
			this._leaguesStore.fetchMyLeagues({limit: 3}),
			this._teamStore.fetchTeam(),
		]).catch(this.onError);

		runInAction(() => {
			this._isLoaded = true;
		});
	}
}
