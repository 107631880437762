import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent, ReactNode} from "react";
import type {SelectChangeEvent} from "@mui/material";
import type {IUpdateLeaguePayload} from "data/providers/api/leagues.api.provider";
import type {IUserStore} from "data/stores/user/user.store";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {identity, isEqual, values} from "lodash";
import {LeaguePrivacy, LeagueStatus, ModalType, RequestState, RoundStatus} from "data/enums";
import {extractErrorMessage} from "data/utils";

interface IProps {
	leagueId: number;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

const defaultForm = {
	leagueName: "",
	startId: 0,
	privacy: LeaguePrivacy.PRIVATE,
};

export interface ILeagueSettingsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get userID(): number;
	get league(): ILeague | null;
	get rounds(): IRound[];
	get isLoading(): boolean;
	get isUpdateButtonDisabled(): boolean;
	get isLeagueNameFieldDisabled(): boolean;
	get isLeagueStarted(): boolean;
	get isFormDisabled(): boolean;
	get formValue(): IFormValue;
	get isLeagueUpdated(): boolean;

	handleUpdateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	startRoundChange: (event: SelectChangeEvent<unknown>, _: ReactNode) => void;
}

@injectable()
export class LeagueSettingsController implements ILeagueSettingsController {
	@observable private _leagueId?: number;
	@observable private _isLeagueUpdated = false;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _leagueUpdatedTextTimeout?: ReturnType<typeof setTimeout>;

	@observable private _requestsStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	@observable private _formValue: IFormValue = defaultForm;

	get isLeagueUpdated() {
		return this._isLeagueUpdated;
	}

	private get leagueID() {
		return this._leagueId!;
	}

	get userID() {
		return this._userStore.user!.id;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get leagueValues() {
		if (this.league) {
			return {
				leagueName: this.league.name,
				startId: this.league.startId,
				privacy: this.league.privacy,
			};
		}
		return defaultForm;
	}

	@computed get rounds() {
		return this._roundsStore.list.filter((round) => {
			const isScheduled = isEqual(round.status, RoundStatus.Scheduled);
			const isLeagueStartRound = isEqual(this.league?.startId, round.id);

			return isScheduled || isLeagueStartRound;
		});
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestsStates.general, RequestState.PENDING);
	}

	get isFormDisabled() {
		return this.isLoading || this.isLeagueStarted;
	}

	get isLeagueNameFieldDisabled() {
		return this.isLoading;
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isUpdateButtonDisabled() {
		if (this.isLeagueStarted) return !this.isFormValid;
		return this.isFormDisabled || !this.isFormValid;
	}

	get hasFormChanged() {
		return !isEqual(this.leagueValues, this.formValue);
	}

	@computed private get isFormValid() {
		if (!this.hasFormChanged) {
			return false;
		}
		return values(this._formValue).every(identity);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onUpdateLeagueState = () => {
		this._isLeagueUpdated = false;
	};

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => () => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action private createErrorHandlerOf =
		(type: keyof typeof this._requestsStates) => (error: AxiosError<IApiResponse>) => {
			this._requestsStates[type] = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				code: error.response?.status,
				message: extractErrorMessage(error),
			});
		};

	@action private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};

	handleUpdateLeague = () => {
		this.setPendingState("general");

		const {leagueName, startId, privacy} = this._formValue;

		const payload: IUpdateLeaguePayload = {
			name: leagueName,
			leagueId: this.leagueID,
		};

		if (!this.isLeagueStarted) {
			payload.privacy = privacy;
			payload.startId = startId;
		}

		this._leaguesStore
			.updateLeague(payload)
			.then(() => {
				this._isLeagueUpdated = true;
				this._leagueUpdatedTextTimeout = setTimeout(this.onUpdateLeagueState, 5000);
			})
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action startRoundChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
		this._formValue.startId = Number(event.target.value);
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		this._roundsStore
			.fetchRounds()
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));

		this._leagueDisposer = reaction(
			() => this.league,
			() => {
				if (!this.league) return;

				const {startId, name, privacy} = this.league;

				this._formValue.startId = startId;
				this._formValue.leagueName = name;
				this._formValue.privacy = privacy;
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._leagueDisposer?.();

		if (this._leagueUpdatedTextTimeout) clearTimeout(this._leagueUpdatedTextTimeout);
	}
}
