import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {ModalType, RequestState} from "data/enums";
import {get, isEqual} from "lodash";

export interface IBackdoorController extends ViewController {
	get emailField(): string;
	get isLoading(): boolean;
	get isDisabled(): boolean;
	handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSubmit: () => void;
}

@injectable()
export class BackdoorController implements IBackdoorController {
	@observable private _email: string = "";
	@observable private _requestState = RequestState.IDLE;

	get emailField() {
		return this._email;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isDisabled() {
		return !this._email || this.isLoading;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		this._email = event.currentTarget.value;
	};

	@action private requestUser = async () => {
		return this._userStore.requestUser().catch((err) => {
			if (get(err, "response.status") === 418) {
				this._modalsStore.showModal(ModalType.REGISTRATION);
			}
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			code: error.response?.status,
			message: extractErrorMessage(error),
		});
	};

	@action handleSubmit = () => {
		this._requestState = RequestState.PENDING;
		if (this._email) {
			this._userStore
				.login({
					token: `backdoor|${this._email}`,
				})
				.then(this.requestUser)
				.then(this.onSuccess)
				.catch(this.onError);
		}
	};
}
