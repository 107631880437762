import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore, ITab} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import {extractErrorMessage} from "data/utils";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";

interface IProps {
	leagueId: number;
	location: ReturnType<typeof useLocation>;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILeagueController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get isLoading(): boolean;
	get isCommissioner(): boolean;
	get tabs(): ITab[];
}

@injectable()
export class LeagueController implements ILeagueController {
	@observable private _location?: ReturnType<typeof useLocation>;
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _fetchLeagueDisposer?: ReturnType<typeof reaction>;
	@observable private _leagueId?: number;
	@observable private _requestState = RequestState.PENDING;

	get tabs() {
		if (!this._leagueId) return [];
		return this._leaguesStore.getTabs(this._leagueId, this.isCommissioner);
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isCommissioner() {
		return isEqual(this.league?.leagueManager?.userId, this._userStore.user!.id);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore
	) {
		makeAutoObservable(this);
	}

	@action onSuccessLeagueRequest = () => {
		this._requestState = RequestState.SUCCESS;

		const hasValidTab = this.tabs.some((tab) => isEqual(tab.path, this._location?.pathname));

		if (!hasValidTab) {
			const path = this.isCommissioner ? "settings" : "about";
			this._navigate?.(`/league/${this._leagueId!}/${path}`, {replace: true});
		}
	};

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			code: error.response?.status,
			message: extractErrorMessage(error),
		});
	};

	@action onChange({leagueId, navigate, location}: IProps) {
		this._leagueId = leagueId;
		this._location = location;
		this._navigate = navigate;
	}

	@action async init(params: IProps) {
		this.onChange(params);

		await Promise.all([
			this._roundsStore.fetchRounds(),
			this._squadsStore.fetchSquads(),
			this._playersStore.fetchPlayers(),
			this._competitionsStore.fetchCompetitions(),
		]).catch(this.onError);

		this._fetchLeagueDisposer = reaction(
			() => this._leagueId,
			() => {
				if (!this._leagueId) return;

				this._requestState = RequestState.PENDING;

				void this._leaguesStore
					.fetchLeague({leagueId: this._leagueId})
					.then(this.onSuccessLeagueRequest)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._fetchLeagueDisposer?.();
	}
}
